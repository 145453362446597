<template>
  <PageTitle pageTitle="Novo Pedido" mainTitle="Pedido" />

  <v-form @submit.prevent="handleSubmit()">
    <v-row>
      <v-col cols="12" lg="3" md="3">
        <v-card class="mb-10 pingo-card">
          <div class="v-card-header border-bottom">
            <h5 class="mb-0">Informações do cliente</h5>
          </div>

          <div v-if="newOrderForm.user" class="box">
            <h5 class="fw-semibold mb-15">{{ newOrderForm.user.name }}</h5>
            <ul class="list ps-0 mb-0 mt-0">
              <!-- <li class="gray-color position-relative">
                <span class="fw-medium d-inline-block">Documento :</span>
                {{ newOrderForm.user.document }}
              </li>

              <li class="gray-color position-relative">
                <span class="fw-medium d-inline-block">Celular :</span>
                {{ newOrderForm.user.phone }}
              </li> -->

              <li class="gray-color position-relative">
                <span class="fw-medium d-inline-block">Email:</span>
                {{ newOrderForm.user.email ?? "Email não cadastrado" }}
              </li>
            </ul>
          </div>

          <div v-if="newOrderForm.user == null">
            <div class="pingo-form-group without-icon">
              <label class="d-block mb-12 fw-semibold gray-color">
                Agendado para
              </label>

              <TextInput
                v-model="newOrderForm.dateReference"
                :type="'date'"
                :label="'Digite aqui'"
              />
            </div>

            <div
              v-if="
                user.type == UserType.ADMIN || user.type == UserType.BUSINESS
              "
              class="pingo-form-group without-icon mb-0"
            >
              <label class="d-block mb-12 fw-semibold gray-color">
                Selecione um colaborador
              </label>

              <AutoCompleteCollaborators
                v-model="newOrderForm.user"
                :required="true"
                @update:value="(value: UserResponse) => newOrderForm.user = value"
                :error-messages="
                  v$.userId.$errors ? v$.userId.$errors[0]?.$message : undefined
                "
              />
            </div>
          </div>

          <v-btn
            v-if="newOrderForm.user"
            class="text-capitalize w-100 mt-10"
            color="blue-darken-3"
            variant="tonal"
            @click="newOrderForm.user = undefined"
          >
            Alterar Cliente
          </v-btn>
        </v-card>

        <v-card class="mb-10 pingo-card">
          <div class="pingo-form-group without-icon mt-10">
            <label class="d-block mb-12 fw-semibold gray-color">
              Observação do pedido
            </label>

            <TextInput
              :label="'Digite a Observação do Pedido aqui'"
              v-model="newOrderForm.observations"
              :disabled="route.query.order != undefined"
              hide-details
            />
          </div>
        </v-card>
      </v-col>

      <v-col lg="9" md="9">
        <v-card class="create-project-card pingo-card">
          <div class="v-card-header border-bottom">
            <h5 class="mb-0">Informações do Pedido</h5>

            <v-dialog v-model="isNewItemDialogOpen" width="480" scrollable>
              <template v-slot:activator="{ props: activatorProps }">
                <v-btn
                  class="text-capitalize"
                  color="blue-darken-3"
                  variant="tonal"
                  v-bind="activatorProps"
                >
                  Adicionar produto
                </v-btn>
              </template>

              <template v-slot:default="{ isActive }">
                <v-form @submit.prevent="addItemToItemsList()">
                  <v-card class="py-0" title="Adicionar novo item">
                    <v-divider></v-divider>

                    <v-card-text class="px-4">
                      <div class="pingo-form-group without-icon mb-0">
                        <label class="d-block mb-12 fw-semibold gray-color">
                          Produto
                          <span>*</span>
                        </label>

                        <AutoCompleteProducts
                          :required="true"
                          @update:value="mapProductToItem($event)"
                          :error-messages="
                            v$item.itemId.$errors
                              ? v$item.itemId.$errors[0]?.$message
                              : undefined
                          "
                        />
                      </div>

                      <div class="pingo-form-group without-icon mb-0">
                        <label class="d-block mb-12 fw-semibold gray-color">
                          Quantidade
                          <span>*</span>
                        </label>

                        <TextInput
                          :label="'Digite a Quantidade aqui'"
                          :type="'number'"
                          v-model="newOrderItemForm.quantity"
                          @update:value="(value: number) => newOrderItemForm.quantity = value"
                          :error-messages="
                            v$item.quantity.$errors
                              ? v$item.quantity.$errors[0]?.$message
                              : undefined
                          "
                        />
                      </div>

                      <div class="pingo-form-group without-icon mb-0">
                        <label class="d-block mb-12 fw-semibold gray-color">
                          Observação
                        </label>

                        <TextInput
                          :label="'Digite o Observação aqui'"
                          v-model="newOrderItemForm.observation"
                        />
                      </div>
                    </v-card-text>

                    <v-card-actions>
                      <v-btn
                        class="text-capitalize"
                        @click="isActive.value = false"
                      >
                        Fechar
                      </v-btn>

                      <v-spacer></v-spacer>

                      <v-btn
                        class="text-capitalize"
                        color="green-darken-3"
                        variant="tonal"
                        type="submit"
                      >
                        Confirmar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-form>
              </template>
            </v-dialog>
          </div>

          <v-data-table
            class="table"
            :headers="newOrderColumns"
            :items="newOrderForm.items"
            hide-default-footer
          >
            <template v-slot:[`item.name`]="{ item }">
              <a
                class="d-flex align-items-center product-info"
                style="min-width: 400px"
              >
                <v-img
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/3f/Placeholder_view_vector.svg/1022px-Placeholder_view_vector.svg.png?20220519031949"
                  class="border-radius me-12"
                  width="60"
                  alt="product"
                  style="min-width: 60px"
                />
                <div class="title">
                  <h5 class="fw-semibold mb-1">{{ item.name }}</h5>
                  <span class="d-block muted-color fw-medium">
                    Descrição:
                    <span class="gray-color">{{ item.description }}</span>
                  </span>
                </div>
              </a>
            </template>

            <template v-slot:[`item.price`]="{ item }">
              {{
                new Intl.NumberFormat("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                }).format(item.price ?? 0)
              }}
            </template>

            <template v-slot:[`item.observation`]="{ item }">
              <span
                v-if="item.observation"
                class="text-capitalize badge rounded-pill text-soft-danger"
              >
                {{ item.observation }}
              </span>

              <span
                v-if="!item.observation"
                class="text-capitalize badge rounded-pill text-soft-warning"
              >
                N/A
              </span>
            </template>

            <template v-slot:[`item.total`]="{ item }">
              {{
                new Intl.NumberFormat("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                }).format((item.price ?? 0) * item.quantity)
              }}
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <div
                class="d-flex justify-content-center align-items-center ga-6"
              >
                <v-btn
                  class="text-capitalize w-100"
                  color="red-darken-3"
                  variant="tonal"
                  @click="removeItemFromItemList(item.itemId)"
                >
                  Remover
                </v-btn>
              </div>
            </template>

            <template v-slot:no-data> Não há nenhum produto </template>

            <template v-slot:loading>
              <v-skeleton-loader type="table-row@10"></v-skeleton-loader>
            </template>
          </v-data-table>

          <div class="table-responsive">
            <table class="table">
              <tbody>
                <!-- Summary -->
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td class="text-end fw-semibold gray-color">Sub Total:</td>
                  <td class="fw-semibold text-end">
                    {{
                      new Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      }).format(subtotal)
                    }}
                  </td>
                </tr>

                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td class="text-end fw-semibold gray-color">Desconto:</td>
                  <td class="fw-semibold text-end">
                    {{
                      new Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      }).format(discount)
                    }}
                  </td>
                </tr>

                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td class="text-end fw-semibold gray-color">
                    Taxa de Entrega:
                  </td>
                  <td class="fw-semibold text-end">
                    {{
                      new Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      }).format(deliveryFee)
                    }}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td class="text-end fw-semibold">Total (BRL):</td>
                  <td class="fw-semibold text-end">
                    {{
                      new Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      }).format(total)
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <v-row> </v-row>

          <div
            class="d-block d-md-flex align-items-center justify-space-between text-end ga-10 mt-20"
          >
            <v-alert
              v-if="newOrderForm.items.length == 0"
              color="red-lighten-1"
              type="error"
              class="text-start mb-10 mb-md-0"
            >
              Selecione
              <span
                v-if="
                  user.type == UserType.ADMIN || user.type == UserType.BUSINESS
                "
              >
                um colaborador e
              </span>
              <strong> ao menos um </strong>
              produto para criar um pedido.
            </v-alert>

            <button
              type="submit"
              class="default-btn ms-auto"
              :disabled="isLoadingOrderRequest"
            >
              <v-progress-circular
                v-if="isLoadingOrderRequest"
                indeterminate
                size="18"
                width="2"
              ></v-progress-circular>

              <span v-if="isLoadingOrderRequest"> Finalizando Pedido... </span>
              <span v-if="!isLoadingOrderRequest"> Finalizar Pedido </span>
            </button>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-form>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, Ref, ref, watch } from "vue";

import { useRoute, useRouter } from "vue-router";

import { useVuelidate } from "@vuelidate/core";

import { useAuthStore } from "@/stores/auth.store";

import { useOrder } from "@/composables/useOrder";

import PageTitle from "@/components/Common/PageTitle.vue";

import TextInput from "@/components/Forms/BasicElements/Input/TextInput.vue";

import AutoCompleteCollaborators from "@/components/Forms/AdvancedElements/AutoComplete/AutoCompleteCollaborators.vue";
import AutoCompleteProducts from "@/components/Forms/AdvancedElements/AutoComplete/AutoCompleteProducts.vue";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { UserResponse } from "@/types/response/user";
import { UserType } from "@/types/enum/userType.enum";

export default defineComponent({
  name: "CreateOrderPage",
  components: {
    PageTitle,
    TextInput,
    AutoCompleteCollaborators,
    AutoCompleteProducts,
  },
  setup() {
    const authStore = useAuthStore();

    const route = useRoute();
    const router = useRouter();

    const {
      newOrderItemForm,
      newOrderItemFormRules,
      newOrderColumns,
      newOrderForm,
      newOrderFormRules,
      createNewOrderItemForm,
      createOrder,
    } = useOrder();

    const user: Ref = ref(authStore.getUser);

    // Valores adicionais para desconto e taxa de entrega
    const discount = ref(0.0);
    const deliveryFee = ref(0.0);

    // Computed para calcular o subtotal, total, desconto e taxa de entrega
    const subtotal = computed(() => {
      return newOrderForm.value.items.reduce((sum, item) => {
        return sum + (item.price ?? 0) * item.quantity;
      }, 0);
    });

    const total = computed(() => {
      return subtotal.value - discount.value + deliveryFee.value;
    });

    const isLoadingOrderRequest = ref(false);
    const isNewItemDialogOpen = ref(false);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const mapProductToItem = (item: any) => {
      newOrderItemForm.value.itemId = item.itemId;
      newOrderItemForm.value.name = item.name;
      newOrderItemForm.value.description = item.description;
      newOrderItemForm.value.price = item.price;
    };

    const addItemToItemsList = async () => {
      const result = await v$item.value.$validate();

      if (!result) return;

      newOrderItemForm.value.quantity = parseInt(
        newOrderItemForm.value.quantity as unknown as string,
        10
      );

      newOrderForm.value.items.push(newOrderItemForm.value);

      newOrderItemForm.value = createNewOrderItemForm();

      v$item.value.$reset();

      isNewItemDialogOpen.value = !isNewItemDialogOpen.value;
    };

    const removeItemFromItemList = (itemId: number) => {
      newOrderForm.value.items = newOrderForm.value.items.filter(
        (item) => item.itemId !== itemId
      );
    };

    const handleSubmit = async () => {
      if (!newOrderForm.value.user || !newOrderForm.value.userId) {
        const result = await v$.value.$validate();

        if (!result) return;
      }

      // Preparar os dados no formato necessário antes da validação
      const newOrderFormPayload = {
        userId: newOrderForm.value.user?.userId ?? newOrderForm.value.userId,
        totalAmount: newOrderForm.value.items.reduce((sum, item) => {
          return sum + (item.price ?? 0) * item.quantity;
        }, 0),
        items: newOrderForm.value.items.map((item) => ({
          itemId: item.itemId,
          quantity: item.quantity,
          observation: item.observation,
        })),
        dateReference: newOrderForm.value.dateReference,
      };

      const result = await v$.value.$validate();

      if (!result) return;

      isLoadingOrderRequest.value = !isLoadingOrderRequest.value;

      const response = await createOrder(newOrderFormPayload);

      isLoadingOrderRequest.value = !isLoadingOrderRequest.value;

      if (response) router.go(-1);
    };

    watch(
      () => newOrderForm.value.user,
      (newValue) => {
        if (newValue) newOrderForm.value.userId = newValue.userId;
        else newOrderForm.value.userId = undefined;
      }
    );

    const v$ = useVuelidate(newOrderFormRules, newOrderForm);
    const v$item = useVuelidate(newOrderItemFormRules, newOrderItemForm);

    onMounted(() => {
      if (user.value.type == UserType.COLLABORATOR) {
        newOrderForm.value.userId = user.value.userId;
      }

      if (route.query.order) {
        const queryOrder = JSON.parse(route.query.order as string);

        newOrderForm.value.user = queryOrder.user;
        newOrderForm.value.observations =
          "Pedido cópia referente ao pedido: " + queryOrder.orderId;

        queryOrder.items.forEach(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (item: {
            item: { itemId: number; name: string; description: string };
            quantity: number;
            unitPrice: number;
            observations: string;
          }) => {
            const newItem = {
              itemId: item.item.itemId,
              name: item.item.name,
              description: item.item.description,
              quantity: item.quantity,
              price: item.unitPrice,
              observation: item.observations,
            };

            newOrderForm.value.items.push(newItem);
          }
        );
      }
    });

    return {
      user,
      UserType,
      v$,
      v$item,
      route,
      newOrderColumns,
      isLoadingOrderRequest,
      newOrderForm,
      newOrderItemForm,
      discount,
      deliveryFee,
      subtotal,
      total,
      addItemToItemsList,
      removeItemFromItemList,
      isNewItemDialogOpen,
      mapProductToItem,
      handleSubmit,
    };
  },
});
</script>

<style lang="scss" scoped>
.pingo-form-group {
  margin-bottom: 0px;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  &::-webkit-scrollbar:vertical {
    width: 10px;
  }
  &::-webkit-scrollbar:horizontal {
    height: 10px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    border: 2px solid var(--whiteColor);
    background-color: rgba(0, 0, 0, 0.2);
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: var(--whiteColor);
  }
}
table,
.table {
  width: 100%;
  border-collapse: collapse;

  thead {
    tr {
      th {
        background-color: #f7faff;
        vertical-align: middle;
        white-space: nowrap;
        text-align: center;
        position: relative;
        padding: 18px 20px;

        &::after {
          top: 50%;
          right: 0;
          width: 1px;
          content: "";
          height: 17px;
          position: absolute;
          background-color: #e7ebf5;
          transform: translateY(-50%);
        }
        &:last-child {
          &::after {
            display: none;
          }
        }
      }
    }
  }
  tbody {
    tr {
      td {
        text-align: center;
        padding: 18px 20px;
        white-space: nowrap;
        border-bottom-color: #f7faff;
        border-bottom: 1px solid #f7faff;

        .product-info {
          padding-right: 25px;

          h5 {
            font-size: 15px;
          }
          span {
            font-size: 14px;
          }
        }
        .rating {
          i {
            line-height: 1;
            color: #ffbc2b;
            margin: {
              left: 2px;
              right: 2px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  table,
  .table {
    thead {
      tr {
        th {
          padding: 15px;
        }
      }
    }
    tbody {
      tr {
        td {
          padding: 15px;

          .product-info {
            padding-right: 50px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  table,
  .table {
    thead {
      tr {
        th {
          padding: 15px 20px;
        }
      }
    }
    tbody {
      tr {
        td {
          padding: 15px 20px;
        }
      }
    }
  }
}

.box {
  h5 {
    font-size: 16px;
  }
  .list {
    list-style-type: none;

    li {
      margin-bottom: 13px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .box {
    h5 {
      font-size: 15px;
    }
  }
}
</style>
