<template>
  <div>
    <v-btn
      class="text-capitalize"
      color="green-darken-3"
      variant="tonal"
      @click="generatePDF"
    >
      Gerar Nota
    </v-btn>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import { jsPDF } from "jspdf"; // Importando jsPDF

export default defineComponent({
  name: "PrinterComponent",
  props: {
    order: {
      // eslint-disable-next-line no-undef, @typescript-eslint/no-explicit-any
      type: Object as PropType<any>,
      required: true,
    },
  },
  setup(props) {
    // Função para formatar o número do pedido no formato #0001
    const formatOrderId = (orderId: number) => {
      return `#${String(orderId).padStart(4, "0")}`;
    };

    const generatePDF = () => {
      const doc = new jsPDF({
        unit: "mm",
        format: [48, 297], // Ajustando para 48mm de largura
      });

      const leftMargin = 3;
      const topMargin = 10;
      const pageWidth = 48; // Largura total da página
      const textWidth = pageWidth - leftMargin * 2; // Largura ajustada para texto
      const pageHeight = 297;
      const bottomMargin = 10;
      let y = topMargin;

      const checkPageBreak = (lineHeight = 6) => {
        if (y + lineHeight >= pageHeight - bottomMargin) {
          doc.addPage();
          y = topMargin;
        }
      };

      // Título
      doc.setFontSize(10);
      doc.setFont("helvetica", "bold");
      doc.text("Comprovante de Pedido", leftMargin, y);
      y += 8;

      // Informações da empresa
      doc.setFontSize(8);
      doc.setFont("helvetica", "normal");
      const companyInfo = [
        "MÃOS QUE NUTREM LTDA",
        "CNPJ: 55.263.890/0001-16",
        "AV. CENTENARIO 116 BOX IT 33,",
        "Zona 8 - Maringá - PR",
        "--------------------------------------",
      ];

      companyInfo.forEach((text) => {
        checkPageBreak();
        const splitText = doc.splitTextToSize(text, textWidth);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        splitText.forEach((line: any) => {
          doc.text(line, leftMargin, y);
          y += 4;
        });
      });

      // Informações do pedido
      checkPageBreak();
      doc.setFont("helvetica", "bold");
      doc.text(`Pedido: ${formatOrderId(props.order.orderId)}`, leftMargin, y);
      y += 5;

      // Informações do usuário
      checkPageBreak();
      doc.text(`Nome: ${props.order.user.name}`, leftMargin, y);
      y += 5;
      doc.setFont("helvetica", "normal");
      doc.text(
        `Horário: ${new Date(props.order.createdAt).toLocaleString()}`,
        leftMargin,
        y
      );
      y += 10;

      // Endereço de entrega
      const defaultAddress = props.order.address.find(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (address: any) => address.isDefault === 1
      );

      if (defaultAddress) {
        checkPageBreak();
        doc.setFont("helvetica", "bold");
        doc.text("Endereço de entrega:", leftMargin, y);
        y += 5;

        const addressText = `${defaultAddress.street}, ${defaultAddress.number}, ${defaultAddress.neighborhood}, ${defaultAddress.city}, ${defaultAddress.state}, ${defaultAddress.zipCode}, ${defaultAddress.country}`;
        const splitAddress = doc.splitTextToSize(addressText, textWidth);

        doc.setFont("helvetica", "normal");
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        splitAddress.forEach((line: any) => {
          checkPageBreak();
          doc.text(line, leftMargin, y);
          y += 4;
        });

        y += 5;
      }

      // Cabeçalho dos produtos
      checkPageBreak();
      doc.setFont("helvetica", "bold");
      doc.text("Produtos solicitados", leftMargin, y);
      y += 5;
      doc.setFont("helvetica", "normal");
      doc.text("Qtd.  Descrição", leftMargin, y);
      y += 5;
      doc.text("--------------------------------------", leftMargin, y);
      y += 5;

      // Itens do pedido
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      props.order.items.forEach((item: any) => {
        checkPageBreak();
        doc.setFont("helvetica", "bold");
        const formattedItemName = `${item.quantity}x  -  ${item.item.name}`;
        const splitItemName = doc.splitTextToSize(formattedItemName, textWidth);

        doc.setFont("helvetica", "normal");
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        splitItemName.forEach((line: any) => {
          checkPageBreak();
          doc.text(line, leftMargin, y);
          y += 4;
        });

        y += 3;

        // Observações
        const observation = item.observations
          ? `OBSERVAÇÃO: ${item.observations.toUpperCase()}`
          : "OBSERVAÇÃO: N/A";
        const splitObservation = doc.splitTextToSize(observation, textWidth);

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        splitObservation.forEach((line: any) => {
          checkPageBreak();
          doc.text(line, leftMargin, y);
          y += 4;
        });

        y += 3;
      });

      // Gerar e imprimir o PDF
      const pdfBlob = doc.output("blob");
      const pdfURL = URL.createObjectURL(pdfBlob);
      const printWindow = window.open(pdfURL, "_blank");
      if (printWindow) {
        printWindow.onload = () => {
          printWindow.focus();
          printWindow.print();
        };
      }
    };

    return {
      generatePDF, // Expondo a função de gerar PDF
    };
  },
});
</script>

<style lang="scss" scoped>
.box {
  h5 {
    font-size: 16px;
  }
  .list {
    list-style-type: none;

    li {
      margin-bottom: 13px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .box {
    h5 {
      font-size: 15px;
    }
  }
}
</style>
