<template>
  <form class="mb-6" @submit.prevent="handleFilter()">
    <v-row>
      <!-- Filters -->
      <v-col class="py-0" cols="12" lg="12" md="12">
        <v-card class="single-stats-box pingo-card">
          <div class="v-card-header border-bottom">
            <h5 class="mb-0">Filtros</h5>
          </div>

          <v-row>
            <v-col v-if="user.type == UserType.ADMIN" cols="12" lg="3">
              <div class="pingo-form-group without-icon">
                <AutoCompleteBusinesses
                  v-model="userId"
                  @update:value="(value: UserResponse) => (updateUserId(value))"
                  :required="false"
                  :return-object="false"
                />
              </div>
            </v-col>

            <v-col cols="12" lg="3">
              <div class="pingo-form-group without-icon">
                <AutoCompleteStatus
                  v-model="status"
                  @update:value="(value: string) => (updateStatus(value))"
                  :required="false"
                  :return-object="false"
                />
              </div>
            </v-col>

            <v-col cols="12" lg="3">
              <div class="pingo-form-group without-icon">
                <TextInput
                  :type="'date'"
                  :label="'Data Inicial'"
                  v-model="dateStart"
                  :required="false"
                  @update:value="
                    (initial_datetime: string) => updateInitDate(initial_datetime)
                  "
                />
              </div>
            </v-col>

            <v-col cols="12" lg="3">
              <div class="pingo-form-group without-icon">
                <TextInput
                  :type="'date'"
                  :label="'Data Final'"
                  :required="false"
                  v-model="dateEnd"
                  @update:value="
                    (final_datetime: string) => updateFinishDate(final_datetime)
                  "
                />
              </div>
            </v-col>

            <v-col cols="12" lg="3" class="mt-auto mb-auto">
              <v-btn
                class="w-100"
                type="submit"
                size="large"
                variant="outlined"
              >
                Aplicar
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </form>
</template>

<script lang="ts">
import { defineComponent, onMounted, Ref, ref, watch } from "vue";

import { useAuthStore } from "@/stores/auth.store";
import { useDashboardStore } from "@/stores/dashboard.store";

import AutoCompleteBusinesses from "@/components/Forms/AdvancedElements/AutoComplete/AutoCompleteBusinesses.vue";
import AutoCompleteStatus from "@/components/Forms/AdvancedElements/AutoComplete/AutoCompleteStatus.vue";
import TextInput from "@/components/Forms/BasicElements/Input/TextInput.vue";

import { UserType } from "@/types/enum/userType.enum";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { UserResponse } from "@/types/response/user";

export default defineComponent({
  name: "DashboardFilters",
  components: {
    AutoCompleteBusinesses,
    AutoCompleteStatus,
    TextInput,
  },
  setup() {
    const authStore = useAuthStore();
    const dashboardStore = useDashboardStore();

    const user: Ref = ref(authStore.getUser);

    const userId = ref(dashboardStore.filters.userId);
    const status = ref(dashboardStore.filters.status);
    const dateStart = ref(dashboardStore.filters.dateStart);
    const dateEnd = ref(dashboardStore.filters.dateEnd);

    const formatDate = (value: string): string => {
      try {
        // Verifica se o valor está no formato esperado: YYYY-MM-DD
        const datePattern = /^\d{4}-\d{2}-\d{2}$/;
        if (!datePattern.test(value)) {
          throw new Error("Formato de data inválido");
        }

        // Divide o valor em partes (ano, mês, dia)
        const [year, month, day] = value.split("-");

        // Retorna no mesmo formato sem aplicar transformações
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate;
      } catch (error) {
        throw new Error("Formato de data inválido");
      }
    };

    const updateUserId = (value: UserResponse) => (userId.value = value.userId);
    const updateStatus = (value: string) => (status.value = value);

    const updateInitDate = (value: string) => (dateStart.value = value);

    const updateFinishDate = (value: string) => (dateEnd.value = value);

    const handleFilter = () => {
      dashboardStore.setFilters({
        userId: userId.value,
        status: status.value,
        dateStart: dateStart.value,
        dateEnd: dateEnd.value,
      });
    };

    watch(
      () => dashboardStore.filters,
      (newFilters) => {
        userId.value = newFilters.userId;
        status.value = newFilters.status;
        dateStart.value = newFilters.dateStart
          ? formatDate(newFilters.dateStart)
          : undefined;
        dateEnd.value = newFilters.dateEnd
          ? formatDate(newFilters.dateEnd)
          : undefined;
      }
    );

    onMounted(() => {
      const getLocalDate = () => {
        return new Date().toISOString().split("T")[0];
      };

      dateStart.value = formatDate(getLocalDate());
      dateEnd.value = formatDate(getLocalDate());
    });

    return {
      UserType,
      user,
      userId,
      status,
      dateStart,
      dateEnd,
      handleFilter,
      updateUserId,
      updateStatus,
      updateInitDate,
      updateFinishDate,
    };
  },
});
</script>

<style scoped>
.pingo-form-group {
  margin-bottom: 0px;
}
</style>
