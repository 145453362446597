import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "v-card-header d-flex flex-column align-items-start border-bottom" }
const _hoisted_2 = { class: "gray-color" }
const _hoisted_3 = { class: "top align-items-center" }
const _hoisted_4 = { class: "d-flex ga-1" }
const _hoisted_5 = { class: "d-block gray-color fw-semibold" }
const _hoisted_6 = {
  key: 0,
  class: "d-flex ga-1"
}
const _hoisted_7 = { class: "d-block gray-color fw-semibold" }
const _hoisted_8 = {
  key: 0,
  class: "track-list ps-0 mb-0"
}
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { class: "d-block gray-color mb-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_skeleton_loader = _resolveComponent("v-skeleton-loader")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_v_card, {
          key: 0,
          class: "pingo-card"
        }, {
          default: _withCtx(() => [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(6, (n) => {
              return _createElementVNode("div", { key: n }, [
                _createVNode(_component_v_skeleton_loader, { type: "list-item-avatar" }),
                _createVNode(_component_v_skeleton_loader, { type: "text" })
              ])
            }), 64))
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (!_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_v_card, {
          key: 1,
          class: "tacking-box pingo-card"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _cache[0] || (_cache[0] = _createElementVNode("h5", { class: "mb-0" }, "Rastrear Pedido", -1)),
              _createElementVNode("span", _hoisted_2, " Última atualização em " + _toDisplayString(new Intl.DateTimeFormat("pt-BR", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          }).format(new Date(_ctx.order.updatedAt))), 1)
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _cache[1] || (_cache[1] = _createElementVNode("span", { class: "fw-semibold d-block mb-8" }, "ID do Pedido:", -1)),
                _createElementVNode("span", _hoisted_5, " #" + _toDisplayString(_ctx.order?.orderId), 1)
              ]),
              (_ctx.order?.user)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _cache[2] || (_cache[2] = _createElementVNode("span", { class: "fw-semibold d-block mb-8" }, "Colaborador:", -1)),
                    _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.order.user.name), 1)
                  ]))
                : _createCommentVNode("", true)
            ]),
            (_ctx.remainingStatuses.length > 0)
              ? (_openBlock(), _createElementBlock("ul", _hoisted_8, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.remainingStatuses, (status) => {
                    return (_openBlock(), _createElementBlock("li", {
                      key: status,
                      class: "position-relative"
                    }, [
                      _createElementVNode("h5", {
                        class: _normalizeClass(["mb-10 fw-semibold", { 'text-red-darken-4': status === 'cancelled' }])
                      }, [
                        _createTextVNode(_toDisplayString(_ctx.formatStatus(status)) + " ", 1),
                        (_ctx.order.status === status)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_9, "(Atual)"))
                          : _createCommentVNode("", true)
                      ], 2),
                      _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.getStatusDate(status)), 1),
                      _createElementVNode("p", null, _toDisplayString(_ctx.orderHistoryStatus(status)), 1)
                    ]))
                  }), 128))
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}