<template>
  <v-autocomplete
    label="Selecione um Status"
    :items="statuses"
    :item-value="'value'"
    :item-title="'name'"
    :return-object="false"
    :clearable="false"
    :hide-details="!required"
    :no-data-text="'Nenhum status disponível'"
    @update:model-value="(value) => $emit('update:value', value)"
  >
  </v-autocomplete>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "AutoCompleteStatus",
  props: {
    required: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:value"],
  setup() {
    const statuses = [
      { value: "pending", name: "Pendente" },
      { value: "confirmed", name: "Confirmado" },
      { value: "shipped", name: "Enviado" },
      { value: "delivered", name: "Entregue" },
      { value: "cancelled", name: "Cancelado" },
    ];

    return {
      statuses,
    };
  },
});
</script>

<style scoped>
.v-select .v-select__selection-text {
  overflow: hidden !important;
}
</style>
