<template>
  <div>
    <v-btn
      class="text-capitalize"
      color="green-darken-3"
      variant="tonal"
      @click="exportToCSV"
    >
      Gerar CSV
    </v-btn>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

interface Order {
  orderId: number;
  user: {
    userId: number;
    name: string;
    userParent?: {
      userId: number;
      name: string;
    };
  };
  totalAmount: number;
  status: "pending" | "confirmed" | "shipped" | "delivered" | "cancelled";
  dateReference: string;
  createdAt: string;
  updatedAt: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  items?: any[];
}

export default defineComponent({
  name: "ExportToCSVComponent",
  props: {
    orders: {
      type: Array as PropType<Order[]>,
      required: true,
    },
  },
  setup(props) {
    const translateStatus = (status: string): string => {
      const translations: Record<string, string> = {
        pending: "Pendente",
        confirmed: "Confirmado",
        shipped: "Enviado",
        delivered: "Entregue",
        cancelled: "Cancelado",
      };
      return translations[status] || status;
    };

    const formatDate = (dateString: string): string => {
      const options: Intl.DateTimeFormatOptions = {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      };
      return new Date(dateString).toLocaleString("pt-BR", options);
    };

    const formatCurrency = (amount: number): string => {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(amount);
    };

    const exportToCSV = () => {
      const csvHeader = [
        "ID do Pedido",
        "Colaborador",
        "Empresa",
        "Valor Total",
        "Produtos",
        "Status",
        "Entregue Em",
        "Criado Em",
      ];

      const csvRows = props.orders.map((order) => [
        order.orderId,
        order.user.name,
        order.user.userParent?.name || "",
        formatCurrency(order.totalAmount),
        order.items?.map((i) => i?.item.name).join(", "),
        translateStatus(order.status),
        formatDate(order.dateReference),
        formatDate(order.createdAt),
      ]);

      const csvContent = [csvHeader, ...csvRows]
        .map((row) => row.join(","))
        .join("\n");

      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", "relatorio_pedidos.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    return {
      exportToCSV,
    };
  },
});
</script>

<style lang="scss" scoped>
.box {
  h5 {
    font-size: 16px;
  }
  .list {
    list-style-type: none;

    li {
      margin-bottom: 13px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .box {
    h5 {
      font-size: 15px;
    }
  }
}
</style>
