import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pingo-form-group without-icon" }
const _hoisted_2 = { class: "pingo-form-group without-icon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AutoCompleteBusinesses = _resolveComponent("AutoCompleteBusinesses")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_AutoCompleteStatus = _resolveComponent("AutoCompleteStatus")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createElementBlock("form", {
    class: "mb-6",
    onSubmit: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (_ctx.handleFilter()), ["prevent"]))
  }, [
    _createVNode(_component_v_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, {
          class: "py-0",
          cols: "12",
          lg: "12",
          md: "12"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, { class: "single-stats-box pingo-card" }, {
              default: _withCtx(() => [
                _cache[6] || (_cache[6] = _createElementVNode("div", { class: "v-card-header border-bottom" }, [
                  _createElementVNode("h5", { class: "mb-0" }, "Filtros")
                ], -1)),
                _createVNode(_component_v_row, null, {
                  default: _withCtx(() => [
                    (_ctx.user.type == _ctx.UserType.ADMIN)
                      ? (_openBlock(), _createBlock(_component_v_col, {
                          key: 0,
                          cols: "12",
                          lg: "3"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_1, [
                              _createVNode(_component_AutoCompleteBusinesses, {
                                modelValue: _ctx.userId,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.userId) = $event)),
                                "onUpdate:value": _cache[1] || (_cache[1] = (value) => (_ctx.updateUserId(value))),
                                required: false,
                                "return-object": false
                              }, null, 8, ["modelValue"])
                            ])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    _createVNode(_component_v_col, {
                      cols: "12",
                      lg: "3"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_2, [
                          _createVNode(_component_AutoCompleteStatus, {
                            modelValue: _ctx.status,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.status) = $event)),
                            "onUpdate:value": _cache[3] || (_cache[3] = (value) => (_ctx.updateStatus(value))),
                            required: false,
                            "return-object": false
                          }, null, 8, ["modelValue"])
                        ])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, {
                      cols: "12",
                      lg: "3",
                      class: "mt-auto mb-auto"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_btn, {
                          class: "w-100",
                          type: "submit",
                          size: "large",
                          variant: "outlined"
                        }, {
                          default: _withCtx(() => _cache[5] || (_cache[5] = [
                            _createTextVNode(" Aplicar ")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 32))
}