<template>
  <div>
    <v-btn
      class="text-capitalize"
      color="blue-darken-3"
      variant="tonal"
      @click="exportToPDF"
    >
      Gerar PDF
    </v-btn>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import jsPDF from "jspdf";
import "jspdf-autotable"; // Importação do plugin

import { ItemResponse } from "@/types/response/orderDetails";

interface Order {
  orderId: number;
  user: {
    userId: number;
    name: string;
    userParent?: {
      userId: number;
      name: string;
    };
  };
  totalAmount: number;
  status: "pending" | "confirmed" | "shipped" | "delivered" | "cancelled";
  dateReference: string;
  createdAt: string;
  updatedAt: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  items?: {
    quantity: number;
    observations: string | null;
    unitPrice: string;
    totalPrice: string;
    item: ItemResponse;
  }[];
}

export default defineComponent({
  name: "ExportToPDFComponent",
  props: {
    orders: {
      type: Array as PropType<Order[]>,
      required: true,
    },
  },
  setup(props) {
    const translateStatus = (status: string): string => {
      const translations: Record<string, string> = {
        pending: "Pendente",
        confirmed: "Confirmado",
        shipped: "Enviado",
        delivered: "Entregue",
        cancelled: "Cancelado",
      };
      return translations[status] || status;
    };

    const formatDate = (dateString: string): string => {
      const options: Intl.DateTimeFormatOptions = {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      };
      return new Date(dateString).toLocaleString("pt-BR", options);
    };

    const formatCurrency = (amount: number): string => {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(amount);
    };

    const exportToPDF = () => {
      const doc = new jsPDF();

      // Adicionar título
      doc.setFontSize(14);
      doc.text("Relatório de Pedidos", 10, 10);

      // Configuração da tabela
      const tableColumn = [
        "ID do Pedido",
        "Colaborador",
        "Empresa",
        "Valor Total",
        "Produtos",
        "Status",
        "Entregue Em",
        "Criado Em",
      ];

      const tableRows = props.orders.map((order) => [
        order.orderId,
        order.user.name,
        order.user.userParent?.name || "",
        formatCurrency(order.totalAmount),
        order.items?.map((i) => i?.item.name).join(", "),
        translateStatus(order.status),
        formatDate(order.dateReference),
        formatDate(order.createdAt),
      ]);

      // Adicionar a tabela ao PDF
      doc.autoTable({
        head: [tableColumn],
        body: tableRows,
        startY: 20,
        styles: { fontSize: 10 },
      });

      // Salvar o PDF
      doc.save("relatorio_pedidos.pdf");
    };

    return {
      exportToPDF,
    };
  },
});
</script>

<style lang="scss" scoped>
.box {
  h5 {
    font-size: 16px;
  }
  .list {
    list-style-type: none;

    li {
      margin-bottom: 13px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .box {
    h5 {
      font-size: 15px;
    }
  }
}
</style>
