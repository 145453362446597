import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "v-card-header d-flex" }
const _hoisted_2 = { class: "d-flex ga-2" }
const _hoisted_3 = { class: "pingo-table-content" }
const _hoisted_4 = { class: "v-elevation-z8 recentOrdersTable pingo-table" }
const _hoisted_5 = { class: "fw-semibold main-color" }
const _hoisted_6 = {
  key: 0,
  class: "d-flex ga-2"
}
const _hoisted_7 = { key: 1 }
const _hoisted_8 = {
  key: 0,
  class: "text-capitalize badge rounded-pill text-soft-danger"
}
const _hoisted_9 = {
  key: 1,
  class: "text-capitalize badge rounded-pill text-soft-warning"
}
const _hoisted_10 = {
  key: 2,
  class: "text-capitalize badge rounded-pill text-soft-info"
}
const _hoisted_11 = {
  key: 3,
  class: "text-capitalize badge rounded-pill text-soft-primary"
}
const _hoisted_12 = {
  key: 4,
  class: "text-capitalize badge rounded-pill text-soft-success"
}
const _hoisted_13 = { class: "d-flex justify-content-center align-items-center ga-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageTitle = _resolveComponent("PageTitle")!
  const _component_DashboardFilters = _resolveComponent("DashboardFilters")!
  const _component_ExportToCSVComponent = _resolveComponent("ExportToCSVComponent")!
  const _component_ExportToPDFComponent = _resolveComponent("ExportToPDFComponent")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_skeleton_loader = _resolveComponent("v-skeleton-loader")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_ItemsPerPage = _resolveComponent("ItemsPerPage")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_PageTitle, {
      pageTitle: "Relatórios",
      mainTitle: "Relatórios"
    }),
    _createVNode(_component_DashboardFilters),
    _createVNode(_component_v_card, { class: "mb-25 pingo-card" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _cache[1] || (_cache[1] = _createElementVNode("h5", { class: "mb-2" }, "Últimos pedidos", -1)),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_ExportToCSVComponent, { orders: _ctx.orders }, null, 8, ["orders"]),
            _createVNode(_component_ExportToPDFComponent, { orders: _ctx.orders }, null, 8, ["orders"])
          ])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_v_data_table, {
              headers: _ctx.columns,
              items: _ctx.orders,
              loading: _ctx.isLoadingOrders,
              "items-per-page": _ctx.pagination.totalPerPage,
              "hide-default-footer": ""
            }, {
              [`item.orderId`]: _withCtx(({ value }) => [
                _createElementVNode("span", _hoisted_5, _toDisplayString(value), 1)
              ]),
              [`item.userParent`]: _withCtx(({ item }) => [
                _createVNode(_component_router_link, {
                  title: "Clique para ir para detalhes da empresa",
                  to: {
                path: `/usuario/editar/${item.user.userParent.userId}`,
                query: { type: 'BUSINESS' },
              }
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.user.userParent.name) + " ", 1),
                    _createVNode(_component_v_icon, {
                      icon: "mdi-open-in-new",
                      size: "x-small"
                    })
                  ]),
                  _: 2
                }, 1032, ["to"])
              ]),
              [`item.products`]: _withCtx(({ item }) => [
                (item.items && item.items.length)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.items, (product) => {
                        return (_openBlock(), _createElementBlock("span", {
                          key: product.item.itemId,
                          class: "badge"
                        }, _toDisplayString(product.item.name), 1))
                      }), 128))
                    ]))
                  : (_openBlock(), _createElementBlock("span", _hoisted_7, "-"))
              ]),
              [`item.totalAmount`]: _withCtx(({ value }) => [
                _createTextVNode(_toDisplayString(new Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(value)), 1)
              ]),
              [`item.status`]: _withCtx(({ item }) => [
                (item.status === _ctx.OrderStatus.Cancelled)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_8, " Cancelado "))
                  : (item.status === _ctx.OrderStatus.Pending)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_9, " Pendente "))
                    : (item.status === _ctx.OrderStatus.Confirmed)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_10, " Confirmado "))
                      : (item.status === _ctx.OrderStatus.Shipped)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_11, " Enviado "))
                        : (item.status === _ctx.OrderStatus.Delivered)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_12, " Entregue "))
                          : _createCommentVNode("", true)
              ]),
              [`item.actions`]: _withCtx(({ item }) => [
                _createElementVNode("div", _hoisted_13, [
                  _createVNode(_component_router_link, {
                    to: `/pedidos/${item.orderId}`
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_btn, {
                        class: "text-capitalize",
                        color: "blue-darken-3",
                        variant: "tonal",
                        type: "button"
                      }, {
                        default: _withCtx(() => _cache[2] || (_cache[2] = [
                          _createTextVNode(" Ver detalhes ")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 2
                  }, 1032, ["to"])
                ])
              ]),
              "no-data": _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode(" Não há nenhum produto ")
              ])),
              loading: _withCtx(() => [
                _createVNode(_component_v_skeleton_loader, { type: "table-row@10" })
              ]),
              _: 2
            }, 1032, ["headers", "items", "loading", "items-per-page"]),
            _createVNode(_component_ItemsPerPage, {
              class: "mt-15",
              total_pages: _ctx.pagination.totalPages,
              total_items: _ctx.pagination.totalElements,
              "onUpdate:pagination": _cache[0] || (_cache[0] = (pagination) => _ctx.handleUpdatePageProps(pagination))
            }, null, 8, ["total_pages", "total_items"])
          ])
        ])
      ]),
      _: 1
    })
  ], 64))
}