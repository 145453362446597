<template>
  <PageTitle pageTitle="Lista de Pedidos" mainTitle="Pedidos" />

  <OrderFilters
    v-if="
      user.type == UserType.ADMIN ||
      user.type == UserType.BUSINESS ||
      user.type == UserType.ATTENDANT
    "
  />

  <v-card class="mb-25 pingo-card">
    <div class="v-card-header d-flex">
      <h5 class="mb-2">Pedidos</h5>

      <div
        v-if="
          user.type == UserType.COLLABORATOR ||
          user.type == UserType.BUSINESS ||
          user.type == UserType.ATTENDANT
        "
        class="d-flex ga-2"
      >
        <router-link
          class="default-btn pingo small"
          :to="{ name: 'CreateOrderPage' }"
        >
          <i class="ri-add-fill"></i>
          Criar novo pedido
        </router-link>

        <router-link
          v-if="user.type == UserType.ADMIN || user.type == UserType.BUSINESS"
          class="default-btn pingo small"
          :to="{ name: 'CreateOrderBatchPage' }"
        >
          <i class="ri-add-fill"></i>
          Criar pedidos em massa
        </router-link>
      </div>
    </div>

    <div class="pingo-table-content">
      <div class="v-elevation-z8 recentOrdersTable pingo-table">
        <v-data-table
          :headers="columns"
          :items="orders"
          :loading="isLoadingOrders"
          :items-per-page="pagination.totalPerPage"
          hide-default-footer
        >
          <template v-slot:[`item.orderId`]="{ value }">
            <span class="fw-semibold main-color">{{ value }}</span>
          </template>

          <template v-slot:[`item.userParent`]="{ item }">
            <router-link
              v-if="user.type != UserType.COLLABORATOR"
              title="Clique para ir para detalhes da empresa"
              :to="{
                path: `/usuario/editar/${item.user.userParent.userId}`,
                query: { type: 'BUSINESS' },
              }"
            >
              {{ item.user.userParent.name }}

              <v-icon icon="mdi-open-in-new" size="x-small"></v-icon>
            </router-link>

            <span v-else class="fw-semibold">{{
              item.user.userParent.name
            }}</span>
          </template>

          <template v-slot:[`item.totalAmount`]="{ value }">
            {{
              new Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(value)
            }}
          </template>

          <template v-slot:[`item.status`]="{ item }">
            <span
              v-if="item.status === OrderStatus.Cancelled"
              class="text-capitalize badge rounded-pill text-soft-danger"
            >
              Cancelado
            </span>
            <span
              v-else-if="item.status === OrderStatus.Pending"
              class="text-capitalize badge rounded-pill text-soft-warning"
            >
              Pendente
            </span>
            <span
              v-else-if="item.status === OrderStatus.Confirmed"
              class="text-capitalize badge rounded-pill text-soft-info"
            >
              Confirmado
            </span>
            <span
              v-else-if="item.status === OrderStatus.Shipped"
              class="text-capitalize badge rounded-pill text-soft-primary"
            >
              Enviado
            </span>
            <span
              v-else-if="item.status === OrderStatus.Delivered"
              class="text-capitalize badge rounded-pill text-soft-success"
            >
              Entregue
            </span>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex justify-content-center align-items-center ga-6">
              <router-link :to="`/pedidos/${item.orderId}`">
                <v-btn
                  class="text-capitalize"
                  color="blue-darken-3"
                  variant="tonal"
                  type="button"
                >
                  Ver detalhes
                </v-btn>
              </router-link>

              <v-menu
                v-if="
                  user.type == UserType.ADMIN ||
                  user.type == UserType.BUSINESS ||
                  user.type == UserType.PARTNER
                "
              >
                <template v-slot:activator="{ props }">
                  <button
                    type="button"
                    v-bind="props"
                    class="position-relative dot-btn p-0 bg-transparent border-none"
                  >
                    <i class="ri-more-fill"></i>
                  </button>
                </template>

                <v-list class="dotMenu monthMenu" style="width: 250px">
                  <v-dialog
                    v-model="isCancelOrderModalOpen"
                    max-width="480"
                    scrollable
                  >
                    <template v-slot:activator="{ props: activatorProps }">
                      <button
                        v-bind="activatorProps"
                        type="button"
                        class="bg-transparent w-100 border-none"
                      >
                        Cancelar pedido
                      </button>
                    </template>

                    <template v-slot:default="{ isActive }">
                      <v-form
                        @submit.prevent="handleUpdateOrderAsCancelled(item)"
                      >
                        <v-card
                          class="py-0"
                          title="Cancelar pedido em andamento"
                        >
                          <v-divider></v-divider>

                          <v-card-text class="px-4">
                            Tem certeza que deseja cancelar este pedido?
                          </v-card-text>

                          <v-divider></v-divider>

                          <v-card-actions>
                            <v-btn
                              class="text-capitalize"
                              @click="isActive.value = false"
                            >
                              Fechar
                            </v-btn>

                            <v-spacer></v-spacer>

                            <v-btn
                              color="red-darken-3"
                              variant="tonal"
                              type="submit"
                              style="text-transform: none"
                            >
                              Sim, quero cancelar
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-form>
                    </template>
                  </v-dialog>
                </v-list>
              </v-menu>
            </div>
          </template>

          <template v-slot:no-data> Não há nenhum produto </template>

          <template v-slot:loading>
            <v-skeleton-loader type="table-row@10"></v-skeleton-loader>
          </template>
        </v-data-table>

        <ItemsPerPage
          class="mt-15"
          :total_pages="pagination.totalPages"
          :total_items="pagination.totalElements"
          @update:pagination="(pagination: Pagination) => handleUpdatePageProps(pagination)"
        />
      </div>
    </div>
  </v-card>
</template>

<script lang="ts">
import { defineComponent, onMounted, Ref, ref, watch } from "vue";

import EventBus from "@/utils/eventBus";

import useVuelidate from "@vuelidate/core";

import { useAuthStore } from "@/stores/auth.store";
import { useOrderStore } from "@/stores/order.store";

import { useOrder } from "@/composables/useOrder";

import PageTitle from "@/components/Common/PageTitle.vue";

import OrderFilters from "@/components/Orders/Filters/OrderFilters.vue";

import ItemsPerPage from "@/components/Common/ItemsPerPage.vue";

import { UserType } from "@/types/enum/userType.enum";
import { OrderStatus } from "@/types/enum/orderStatus.enum";

import type { OrderResponse } from "@/types/response/order";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type { Pagination } from "@/types/props/pagination";

export default defineComponent({
  name: "OrdersPage",
  components: {
    PageTitle,
    OrderFilters,
    ItemsPerPage,
  },
  setup() {
    const authStore = useAuthStore();
    const orderStore = useOrderStore();

    const {
      columns,
      pagination,
      orders,
      getAllOrders,
      updateOrderForm,
      updateOrderFormRules,
      updateOrder,
    } = useOrder();

    const user: Ref = ref(authStore.getUser);

    const isLoadingOrders = ref(false);

    const isUpdateOrderModalOpen = ref(false);
    const isCancelOrderModalOpen = ref(false);

    const fetchOrdersData = async () => {
      try {
        isLoadingOrders.value = true;

        getAllOrders(
          pagination.value.currentPage,
          pagination.value.totalPerPage
        ).then(() => {
          isLoadingOrders.value = false;
        });
      } catch (error) {
        console.error("Erro ao buscar dados de pedidos:", error);
      }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleUpdatePageProps = async ({ page, size }: any) => {
      pagination.value.currentPage = page;
      pagination.value.totalPerPage = size;

      await fetchOrdersData();
    };

    const handleUpdateOrderAsCancelled = async (order: OrderResponse) => {
      if (!order) throw new Error("Order não encontrado.");

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const updatedOrderPayload = { status: OrderStatus.Cancelled };

      const response = await updateOrder(order.orderId, updatedOrderPayload);

      if (response) {
        EventBus.emit("order-update");

        isCancelOrderModalOpen.value = !isCancelOrderModalOpen.value;
      }
    };

    watch(
      () => orderStore.filters,
      (newFilters) => {
        isLoadingOrders.value = true;

        getAllOrders(
          pagination.value.currentPage,
          pagination.value.totalPerPage,
          undefined,
          undefined,
          newFilters.status,
          newFilters.userId
        ).then(() => {
          isLoadingOrders.value = false;
        });
      },
      { deep: true }
    );

    onMounted(async () => {
      await fetchOrdersData();

      // Listen for the order-update event
      EventBus.on("order-update", async () => {
        await fetchOrdersData();
      });
    });

    const v$ = useVuelidate(updateOrderFormRules, updateOrderForm);

    return {
      UserType,
      OrderStatus,
      v$,
      columns,
      pagination,
      isLoadingOrders,
      user,
      orders,
      updateOrderForm,
      isCancelOrderModalOpen,
      isUpdateOrderModalOpen,
      handleUpdateOrderAsCancelled,
      handleUpdatePageProps,
    };
  },
});
</script>
