import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_btn, {
      class: "text-capitalize",
      color: "green-darken-3",
      variant: "tonal",
      onClick: _ctx.exportToCSV
    }, {
      default: _withCtx(() => _cache[0] || (_cache[0] = [
        _createTextVNode(" Gerar CSV ")
      ])),
      _: 1
    }, 8, ["onClick"])
  ]))
}