<template>
  <PageTitle pageTitle="Relatórios" mainTitle="Relatórios" />

  <DashboardFilters />

  <v-card class="mb-25 pingo-card">
    <div class="v-card-header d-flex">
      <h5 class="mb-2">Últimos pedidos</h5>

      <div class="d-flex ga-2">
        <ExportToCSVComponent :orders="orders" />
        <ExportToPDFComponent :orders="orders" />
      </div>
    </div>

    <div class="pingo-table-content">
      <div class="v-elevation-z8 recentOrdersTable pingo-table">
        <v-data-table
          :headers="columns"
          :items="orders"
          :loading="isLoadingOrders"
          :items-per-page="pagination.totalPerPage"
          hide-default-footer
        >
          <template v-slot:[`item.orderId`]="{ value }">
            <span class="fw-semibold main-color">{{ value }}</span>
          </template>

          <template v-slot:[`item.userParent`]="{ item }">
            <router-link
              title="Clique para ir para detalhes da empresa"
              :to="{
                path: `/usuario/editar/${item.user.userParent.userId}`,
                query: { type: 'BUSINESS' },
              }"
            >
              {{ item.user.userParent.name }}

              <v-icon icon="mdi-open-in-new" size="x-small"></v-icon>
            </router-link>
          </template>

          <template v-slot:[`item.products`]="{ item }">
            <div v-if="item.items && item.items.length" class="d-flex ga-2">
              <span
                v-for="product in item.items"
                :key="product.item.itemId"
                class="badge"
              >
                {{ product.item.name }}
              </span>
            </div>

            <span v-else>-</span>
          </template>

          <template v-slot:[`item.totalAmount`]="{ value }">
            {{
              new Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(value)
            }}
          </template>

          <template v-slot:[`item.status`]="{ item }">
            <span
              v-if="item.status === OrderStatus.Cancelled"
              class="text-capitalize badge rounded-pill text-soft-danger"
            >
              Cancelado
            </span>
            <span
              v-else-if="item.status === OrderStatus.Pending"
              class="text-capitalize badge rounded-pill text-soft-warning"
            >
              Pendente
            </span>
            <span
              v-else-if="item.status === OrderStatus.Confirmed"
              class="text-capitalize badge rounded-pill text-soft-info"
            >
              Confirmado
            </span>
            <span
              v-else-if="item.status === OrderStatus.Shipped"
              class="text-capitalize badge rounded-pill text-soft-primary"
            >
              Enviado
            </span>
            <span
              v-else-if="item.status === OrderStatus.Delivered"
              class="text-capitalize badge rounded-pill text-soft-success"
            >
              Entregue
            </span>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex justify-content-center align-items-center ga-6">
              <router-link :to="`/pedidos/${item.orderId}`">
                <v-btn
                  class="text-capitalize"
                  color="blue-darken-3"
                  variant="tonal"
                  type="button"
                >
                  Ver detalhes
                </v-btn>
              </router-link>
            </div>
          </template>

          <template v-slot:no-data> Não há nenhum produto </template>

          <template v-slot:loading>
            <v-skeleton-loader type="table-row@10"></v-skeleton-loader>
          </template>
        </v-data-table>

        <ItemsPerPage
          class="mt-15"
          :total_pages="pagination.totalPages"
          :total_items="pagination.totalElements"
          @update:pagination="(pagination: Pagination) => handleUpdatePageProps(pagination)"
        />
      </div>
    </div>
  </v-card>
</template>

<script lang="ts">
import { defineComponent, onActivated, onMounted, Ref, ref, watch } from "vue";

import { useAuthStore } from "@/stores/auth.store";
import { useDashboardStore } from "@/stores/dashboard.store";

import { useReport } from "@/composables/useReport";

import PageTitle from "@/components/Common/PageTitle.vue";
import DashboardFilters from "@/components/Dashboard/Filters/DashboardFilters.vue";

import ItemsPerPage from "@/components/Common/ItemsPerPage.vue";
import ExportToCSVComponent from "@/components/Forms/AdvancedElements/Print/ExportToCSVComponent.vue";
import ExportToPDFComponent from "@/components/Forms/AdvancedElements/Print/ExportToPDFComponent.vue";

import { UserType } from "@/types/enum/userType.enum";
import { OrderStatus } from "@/types/enum/orderStatus.enum";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type { Pagination } from "@/types/props/pagination";

export default defineComponent({
  name: "ReportPage",
  components: {
    PageTitle,
    DashboardFilters,
    ItemsPerPage,
    ExportToCSVComponent,
    ExportToPDFComponent,
  },
  setup() {
    const authStore = useAuthStore();
    const dashboardStore = useDashboardStore();

    const {
      columns,
      pagination,
      orderStats,
      getOrderStats,
      orders,
      getAllOrders,
    } = useReport();

    const user: Ref = ref(authStore.getUser);

    const isLoadingOrderStats = ref(false);
    const isLoadingOrders = ref(false);

    const getLocalDate = () => {
      return new Date().toISOString().split("T")[0];
    };

    const formatDate = (value: string): string => {
      try {
        // Verifica se o valor está no formato esperado: YYYY-MM-DD
        const datePattern = /^\d{4}-\d{2}-\d{2}$/;
        if (!datePattern.test(value)) {
          throw new Error("Formato de data inválido");
        }

        // Divide o valor em partes (ano, mês, dia)
        const [year, month, day] = value.split("-");

        // Retorna no mesmo formato sem aplicar transformações
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate;
      } catch (error) {
        throw new Error("Formato de data inválido");
      }
    };

    const fetchOrderStatsData = async () => {
      try {
        isLoadingOrderStats.value = true;

        if (user.value.type == UserType.ADMIN) {
          await getOrderStats(
            formatDate(getLocalDate()),
            formatDate(getLocalDate())
          ).then(() => {
            isLoadingOrderStats.value = false;
          });
        }

        if (user.value.type == UserType.BUSINESS) {
          await getOrderStats(
            formatDate(getLocalDate()),
            formatDate(getLocalDate()),
            user.value.userId
          ).then(() => {
            isLoadingOrderStats.value = false;
          });
        }
      } catch (error) {
        console.error("Erro ao buscar dados de pedidos:", error);
      }
    };

    const fetchOrdersData = async () => {
      try {
        isLoadingOrders.value = true;

        await getAllOrders(
          pagination.value.currentPage,
          pagination.value.totalPerPage,
          formatDate(getLocalDate()),
          formatDate(getLocalDate())
        ).then(() => {
          isLoadingOrders.value = false;
        });

        // Atualizar o array de orders com os itens de todos os status
        // orders.value = orders.value.map((order) => {
        //   // Combinar todos os itens de todos os status em um único array
        //   const allItems = [
        //     ...(orderStats.value?.pending?.items || []),
        //     ...(orderStats.value?.confirmed?.items || []),
        //     ...(orderStats.value?.shipped?.items || []),
        //     ...(orderStats.value?.delivered?.items || []),
        //     ...(orderStats.value?.cancelled?.items || []),
        //   ];

        //   // Filtrar os itens que correspondem ao orderId
        //   const matchingStats = allItems.filter(
        //     (statItem: OrderStatsDetailsItem) => {
        //       return statItem.id === order.orderId;
        //     }
        //   );

        //   // Retornar o pedido atualizado com os itens encontrados
        //   return {
        //     ...order,
        //     items: matchingStats.length ? matchingStats : [],
        //   };
        // });
      } catch (error) {
        console.error("Erro ao buscar dados de pedidos:", error);
      }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleUpdatePageProps = async ({ page, size }: any) => {
      pagination.value.currentPage = page;
      pagination.value.totalPerPage = size;

      await fetchOrdersData();
    };

    watch(
      () => dashboardStore.filters,
      (newFilters) => {
        isLoadingOrderStats.value = true;
        isLoadingOrders.value = true;

        getOrderStats(
          newFilters?.dateStart ? newFilters.dateStart.toString() : undefined,
          newFilters?.dateEnd ? newFilters.dateEnd.toString() : undefined,
          newFilters.userId
        ).then(() => {
          isLoadingOrderStats.value = false;
        });

        getAllOrders(
          pagination.value.currentPage,
          pagination.value.totalPerPage,
          newFilters?.dateStart ? newFilters.dateStart.toString() : undefined,
          newFilters?.dateEnd ? newFilters.dateEnd.toString() : undefined,
          newFilters.status ? newFilters.status : undefined,
          newFilters.userId
        ).then(() => {
          isLoadingOrders.value = false;
        });
      },
      { deep: true }
    );

    onActivated(() => {
      fetchOrderStatsData();
      fetchOrdersData();
    });

    onMounted(() => {
      // dashboardStore.filters.dateStart = formatDate(getLocalDate());
      // dashboardStore.filters.dateEnd = formatDate(getLocalDate());

      fetchOrderStatsData();
      fetchOrdersData();
    });

    return {
      UserType,
      OrderStatus,
      user,
      columns,
      pagination,
      isLoadingOrders,
      isLoadingOrderStats,
      orderStats,
      orders,
      dashboardStore,
      handleUpdatePageProps,
    };
  },
});
</script>
