import { reactive, ref } from "vue";

import { useDate } from "vuetify";

import EventBus from "@/utils/eventBus";

import { useBaseComposable } from "./useBaseComposable";

import { OrderService } from "@/services";

import { isLoading } from "@/shared/base.service";

import type { OrderResponse } from "@/types/response/order";
import type { CreateOrderRequest } from "@/types/request/create/createOrder";
import type { CreateOrderBatchRequest } from "@/types/request/create/createOrderBatch";
import type { UpdateOrderRequest } from "@/types/request/update/updateOrder";
import type { OrderDetailsResponse } from "@/types/response/orderDetails";

import type { Item } from "@/types/model/item";
import { OrderStatsResponse } from "@/types/response/orderStats";

export function useOrder() {
  const date = useDate();

  const { createValidationRules, minValue, required, requiredIf } =
    useBaseComposable();

  const order = ref<OrderDetailsResponse>({
    orderId: 0,
    totalAmount: "",
    address: [],
    user: {
      name: "",
      userId: 0,
      addresses: [],
    },
    items: [],
    history: [],
    dateReference: new Date(),
    status: "pending",
    createdAt: new Date(),
    updatedAt: new Date(),
    observations: "",
  });
  const orderStats = ref<OrderStatsResponse>({
    total: 0,
    pending: {
      total: 0,
      amount: 0,
    },
    confirmed: {
      total: 0,
      amount: 0,
    },
    shipped: {
      total: 0,
      amount: 0,
    },
    delivered: {
      total: 0,
      amount: 0,
      items: [],
    },
    cancelled: {
      total: 0,
      amount: 0,
    },
  });
  const orders = ref<OrderResponse[]>([]);

  const columns = ref<object[]>([
    { title: "ID", align: "start", key: "orderId" },
    {
      title: "Empresa",
      align: "center",
      key: "userParent",
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      value: (item: any) => (item.user.userParent ? item : "-"),
    },
    { title: "Colaborador", align: "center", key: "user.name" },
    { title: "Valor Total", align: "center", key: "totalAmount" },
    {
      title: "Agendado para",
      align: "center",
      key: "createdAt",
      value: (item: OrderResponse) =>
        date.format(item?.dateReference, "keyboardDateTime"),
    },
    {
      title: "Criado Em",
      align: "center",
      key: "createdAt",
      value: (item: OrderResponse) =>
        date.format(item?.createdAt, "keyboardDateTime"),
    },
    {
      title: "Status",
      align: "center",
      key: "status",
      value: (item: OrderResponse) => item,
    },
    {
      title: "Ações",
      align: "center",
      key: "actions",
      sortable: false,
      value: (item: OrderResponse) => item,
    },
  ]);

  const newOrderColumns = ref<object[]>([
    {
      title: "Produto",
      align: "start",
      key: "name",
      sortable: false,
      value: (item: Item) => item,
    },
    {
      title: "Valor Unitário",
      align: "center",
      key: "price",
      sortable: false,
      value: (item: Item) => item,
    },
    { title: "Qtd.", align: "center", key: "quantity", sortable: false },
    {
      title: "Observação",
      align: "center",
      key: "observation",
      sortable: false,
      value: (item: Item) => item,
    },
    {
      title: "Total",
      align: "center",
      key: "total",
      sortable: false,
      value: (item: Item) => item,
    },
    {
      title: "Ações",
      align: "center",
      key: "actions",
      sortable: false,
      value: (item: Item) => item,
    },
  ]);

  const newOrderBatchColumns = ref<object[]>([
    {
      title: "Colaborador",
      align: "start",
      key: "collaborator",
      sortable: false,
    },
    {
      title: "Produto",
      align: "start",
      key: "name",
      sortable: false,
      value: (item: Item) => item,
    },
    {
      title: "Valor",
      align: "center",
      key: "price",
      sortable: false,
      value: (item: Item) => item,
    },
    { title: "Quantidade", align: "center", key: "quantity", sortable: false },
    {
      title: "Observação",
      align: "center",
      key: "observation",
      sortable: false,
      value: (item: Item) => item,
    },
    {
      title: "Ação",
      align: "center",
      key: "actions",
      sortable: false,
      value: (item: OrderResponse) => item,
    },
  ]);

  const pagination = ref({
    totalElements: 0,
    currentPage: 1,
    totalPerPage: 10,
    totalPages: 1,
  });

  const newOrderItemFormRules = createValidationRules({
    itemId: {
      required: {
        validator: required,
        messageKey: "validation.required",
      },
      minValue: {
        validator: minValue(1),
        messageKey: "validation.minValue",
        params: { min: 1 },
      },
    },
    quantity: {
      required: {
        validator: required,
        messageKey: "validation.required",
      },
      minValue: {
        validator: minValue(1),
        messageKey: "validation.minValue",
        params: { min: 1 },
      },
    },
  });

  const newOrderItemForm = ref<Item>({
    itemId: 0,
    name: "",
    description: "",
    observation: "",
    quantity: 1,
    price: 0.0,
  });

  const newOrderFormRules = createValidationRules({
    user: {
      required: {
        // validator: required,
        validator: requiredIf(() => !newOrderForm.value.userId),
        messageKey: "validation.required",
      },
    },
    userId: {
      required: {
        validator: required,
        messageKey: "validation.required",
      },
      // minValue: {
      //   validator: minValue(1),
      //   messageKey: "validation.minValue",
      //   params: { min: 1 },
      // },
    },
    totalAmount: {
      required: {
        validator: required,
        messageKey: "validation.required",
      },
    },
    items: {
      required: {
        validator: required,
        messageKey: "validation.required",
      },
    },
  });

  const newOrderForm = ref<CreateOrderRequest>({
    address: undefined,
    user: undefined,
    userId: undefined,
    totalAmount: 0,
    items: [],
    dateReference: new Date().toISOString().split("T")[0],
    observations: undefined,
  });

  const newOrderBatchFormRules = {
    user: {},
    userId: {
      required,
    },
    totalAmount: {
      required,
    },
    items: {
      required,
      $each: {
        collaborator: {
          required,
        },
        product: {
          required,
        },
        quantity: {
          required,
        },
      },
    },
  };

  const newOrderBatchForm = ref<CreateOrderBatchRequest[]>([]);

  const updateOrderFormRules = createValidationRules({
    orderId: {
      required: {
        validator: required,
        messageKey: "validation.required",
      },
    },
    observations: {},
    status: {
      required: {
        validator: required,
        messageKey: "validation.required",
      },
    },
  });

  const updateOrderForm = reactive<UpdateOrderRequest>({
    orderId: 0,
    observations: "",
    status: undefined,
  });

  const createNewOrderItemForm = (): Item => ({
    itemId: 0,
    quantity: 1,
    observation: "",
    name: "",
    price: 0,
    description: "",
  });

  async function getAllOrders(
    currentPage: number,
    totalElements: number,
    dateStart?: string,
    dateEnd?: string,
    status?: string,
    userId?: number
  ) {
    try {
      const response = await OrderService.GetAllOrders(
        currentPage,
        totalElements,
        dateStart,
        dateEnd,
        status,
        userId
      );

      if (response) {
        orders.value = response.data;

        pagination.value = {
          totalElements: response.totalElements,
          currentPage: response.currentPage,
          totalPerPage: response.totalPerPage,
          totalPages: response.totalPages,
        };
      }

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async function getOrderStats(
    dateStart?: string,
    dateEnd?: string,
    userId?: number
  ) {
    try {
      const response = await OrderService.GetOrderStats(
        dateStart,
        dateEnd,
        userId
      );

      if (response) {
        if (response.delivered && response.delivered.items) {
          response.delivered.items = response.delivered.items.filter(
            (item) => item.id && item.name && item.total
          );
        }

        orderStats.value = response;
      }

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async function getOrderById(id: number) {
    try {
      const response = await OrderService.GetOrderById(id);

      if (response) order.value = response;

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async function createOrder(payload: CreateOrderRequest) {
    try {
      const response = await OrderService.CreateOrder(payload);

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async function createOrderBatch(payload: CreateOrderBatchRequest[]) {
    try {
      const response = await OrderService.CreateOrderBatch(payload);

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async function updateOrder(id: number, payload: UpdateOrderRequest) {
    try {
      const response = await OrderService.UpdateOrder(id, payload);

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async function deleteOrderById(id: number) {
    try {
      const response = await OrderService.DeleteOrderById(id);

      if (response) EventBus.emit("order-update");

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  return {
    isLoading,
    columns,
    newOrderColumns,
    newOrderBatchColumns,
    pagination,
    order,
    orderStats,
    orders,
    newOrderItemFormRules,
    newOrderItemForm,
    newOrderFormRules,
    newOrderForm,
    newOrderBatchFormRules,
    newOrderBatchForm,
    updateOrderFormRules,
    updateOrderForm,
    createNewOrderItemForm,
    getAllOrders,
    getOrderStats,
    getOrderById,
    createOrder,
    createOrderBatch,
    updateOrder,
    deleteOrderById,
  };
}
