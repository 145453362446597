import { defineStore } from "pinia";

import type { OrderFiltersRequest } from "@/types/request/filter/orderFilters";
import { OrderDetailsResponse } from "@/types/response/orderDetails";

export const useOrderStore = defineStore("order", {
  state: () => ({
    copiedOrder: null as OrderDetailsResponse | null,
    filters: {
      userId: undefined,
      status: undefined,
    } as OrderFiltersRequest,
  }),
  actions: {
    setFilters(newFilters: OrderFiltersRequest) {
      this.filters = newFilters;
    },
    setCopiedOrder(order: OrderDetailsResponse) {
      this.copiedOrder = order;
    },
  },
});
