<template>
  <form class="mb-6" @submit.prevent="handleFilter()">
    <v-row>
      <!-- Filters -->
      <v-col class="py-0" cols="12" lg="12" md="12">
        <v-card class="single-stats-box pingo-card">
          <div class="v-card-header border-bottom">
            <h5 class="mb-0">Filtros</h5>
          </div>

          <v-row>
            <v-col v-if="user.type == UserType.ADMIN" cols="12" lg="3">
              <div class="pingo-form-group without-icon">
                <AutoCompleteBusinesses
                  v-model="userId"
                  @update:value="(value: UserResponse) => (updateUserId(value))"
                  :required="false"
                  :return-object="false"
                />
              </div>
            </v-col>

            <!-- <v-col cols="12" lg="4">
              <div class="pingo-form-group without-icon">
                <AutoCompleteCollaborators
                  v-model="userId"
                  @update:value="(value: UserResponse) => (updateUserId(value))"
                  :required="false"
                  :return-object="false"
                />
              </div>
            </v-col> -->

            <v-col cols="12" lg="3">
              <div class="pingo-form-group without-icon">
                <AutoCompleteStatus
                  v-model="status"
                  @update:value="(value: string) => (updateStatus(value))"
                  :required="false"
                  :return-object="false"
                />
              </div>
            </v-col>

            <v-col cols="12" lg="3" class="mt-auto mb-auto">
              <v-btn
                class="w-100"
                type="submit"
                size="large"
                variant="outlined"
              >
                Aplicar
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </form>
</template>

<script lang="ts">
import { defineComponent, Ref, ref, watch } from "vue";

import { useAuthStore } from "@/stores/auth.store";
import { useOrderStore } from "@/stores/order.store";

import AutoCompleteBusinesses from "@/components/Forms/AdvancedElements/AutoComplete/AutoCompleteBusinesses.vue";
// import AutoCompleteCollaborators from "@/components/Forms/AdvancedElements/AutoComplete/AutoCompleteCollaborators.vue";
import AutoCompleteStatus from "@/components/Forms/AdvancedElements/AutoComplete/AutoCompleteStatus.vue";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { UserResponse } from "@/types/response/user";

import { UserType } from "@/types/enum/userType.enum";

export default defineComponent({
  name: "OrderFilters",
  components: {
    AutoCompleteBusinesses,
    // AutoCompleteCollaborators,
    AutoCompleteStatus,
  },
  setup() {
    const authStore = useAuthStore();
    const orderStore = useOrderStore();

    const user: Ref = ref(authStore.getUser);
    const status = ref(orderStore.filters.status);
    const userId = ref(orderStore.filters.userId);

    const updateUserId = (value: UserResponse) => (userId.value = value.userId);

    const updateStatus = (value: string) => (status.value = value);

    const handleFilter = () => {
      orderStore.setFilters({
        userId: userId.value,
        status: status.value,
      });
    };

    watch(
      () => orderStore.filters,
      (newFilters) => {
        userId.value = newFilters.userId;
        status.value = newFilters.status;
      }
    );

    return {
      UserType,
      user,
      userId,
      status,
      handleFilter,
      updateUserId,
      updateStatus,
    };
  },
});
</script>

<style scoped>
.pingo-form-group {
  margin-bottom: 0px;
}
</style>
