import { ref } from "vue";

import { useDate } from "vuetify";

import { OrderService } from "@/services";

import { isLoading } from "@/shared/base.service";

import type { OrderResponse } from "@/types/response/order";
import type { OrderDetailsResponse } from "@/types/response/orderDetails";

import { OrderStatsResponse } from "@/types/response/orderStats";

export function useReport() {
  const date = useDate();

  const order = ref<OrderDetailsResponse>({
    orderId: 0,
    totalAmount: "",
    address: [],
    user: {
      name: "",
      userId: 0,
      addresses: [],
    },
    items: [],
    history: [],
    dateReference: new Date(),
    status: "pending",
    createdAt: new Date(),
    updatedAt: new Date(),
    observations: "",
  });

  const orderStats = ref<OrderStatsResponse>({
    total: 0,
    pending: {
      total: 0,
      amount: 0,
    },
    confirmed: {
      total: 0,
      amount: 0,
    },
    shipped: {
      total: 0,
      amount: 0,
    },
    delivered: {
      total: 0,
      amount: 0,
      items: [],
    },
    cancelled: {
      total: 0,
      amount: 0,
    },
  });

  const orders = ref<OrderResponse[]>([]);

  const columns = ref<object[]>([
    { title: "ID", align: "start", key: "orderId" },
    {
      title: "Empresa",
      align: "center",
      key: "userParent",
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      value: (item: any) => (item.user.userParent ? item : "-"),
    },
    { title: "Colaborador", align: "center", key: "user.name" },
    { title: "Valor Total", align: "center", key: "totalAmount" },
    {
      title: "Agendado para",
      align: "center",
      key: "createdAt",
      value: (item: OrderResponse) =>
        date.format(item?.dateReference, "keyboardDateTime"),
    },
    {
      title: "Produtos",
      align: "center",
      key: "products",
      value: (item: OrderResponse) => item.items,
    },
    {
      title: "Criado Em",
      align: "center",
      key: "createdAt",
      value: (item: OrderResponse) =>
        date.format(item?.createdAt, "keyboardDateTime"),
    },
    {
      title: "Status",
      align: "center",
      key: "status",
      value: (item: OrderResponse) => item,
    },
    {
      title: "Ações",
      align: "center",
      key: "actions",
      sortable: false,
      value: (item: OrderResponse) => item,
    },
  ]);

  const pagination = ref({
    totalElements: 0,
    currentPage: 1,
    totalPerPage: 10,
    totalPages: 1,
  });

  async function getAllOrders(
    currentPage: number,
    totalElements: number,
    dateStart?: string,
    dateEnd?: string,
    status?: string,
    userId?: number
  ) {
    try {
      const response = await OrderService.GetAllOrders(
        currentPage,
        totalElements,
        dateStart,
        dateEnd,
        status,
        userId
      );

      if (response) {
        orders.value = response.data;

        pagination.value = {
          totalElements: response.totalElements,
          currentPage: response.currentPage,
          totalPerPage: response.totalPerPage,
          totalPages: response.totalPages,
        };
      }

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async function getOrderStats(
    dateStart?: string,
    dateEnd?: string,
    userId?: number
  ) {
    try {
      const response = await OrderService.GetOrderStats(
        dateStart,
        dateEnd,
        userId
      );

      if (response) {
        if (response.delivered && response.delivered.items) {
          response.delivered.items = response.delivered.items.filter(
            (item) => item.id && item.name && item.total
          );
        }

        orderStats.value = response;
      }

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async function getOrderById(id: number) {
    try {
      const response = await OrderService.GetOrderById(id);

      if (response) order.value = response;

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  return {
    isLoading,
    columns,
    pagination,
    order,
    orderStats,
    orders,
    getAllOrders,
    getOrderStats,
    getOrderById,
  };
}
