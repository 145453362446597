import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "v-card-header d-flex" }
const _hoisted_2 = { class: "pingo-table-content" }
const _hoisted_3 = { class: "v-elevation-z8 recentOrdersTable pingo-table" }
const _hoisted_4 = {
  key: 0,
  class: "text-capitalize badge rounded-pill text-soft-success"
}
const _hoisted_5 = {
  key: 1,
  class: "text-capitalize badge rounded-pill text-soft-danger"
}
const _hoisted_6 = {
  class: "d-flex align-items-center ga-6",
  style: {"width":"150px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageTitle = _resolveComponent("PageTitle")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_skeleton_loader = _resolveComponent("v-skeleton-loader")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_ItemsPerPage = _resolveComponent("ItemsPerPage")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_PageTitle, {
      pageTitle: "Beneficiários",
      mainTitle: "Beneficiários"
    }),
    _createVNode(_component_v_card, { class: "mb-25 pingo-card" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _cache[2] || (_cache[2] = _createElementVNode("h5", { class: "mb-2" }, "Lista de Beneficiários", -1)),
          _createVNode(_component_router_link, {
            class: "default-btn pingo small",
            to: { name: 'NewUserPage', query: { type: 'BENEFICIARY' } }
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createElementVNode("i", { class: "ri-add-fill" }, null, -1),
              _createTextVNode(" Criar novo usuário ")
            ])),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_v_data_table, {
              headers: _ctx.columns,
              items: _ctx.users,
              loading: _ctx.isLoadingUserBeneficiaries,
              "items-per-page": _ctx.pagination.totalPerPage,
              "hide-default-footer": ""
            }, {
              [`item.isActive`]: _withCtx(({ value }) => [
                (value == true)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_4, " Ativo "))
                  : _createCommentVNode("", true),
                (value == false)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_5, " Inativo "))
                  : _createCommentVNode("", true)
              ]),
              [`item.actions`]: _withCtx(({ item }) => [
                _createElementVNode("div", _hoisted_6, [
                  (_ctx.user.type == 'ADMIN')
                    ? (_openBlock(), _createBlock(_component_router_link, {
                        key: 0,
                        to: {
                  name: 'EditUserPage',
                  params: { id: item.userId },
                  query: { type: 'BENEFICIARY' },
                }
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_btn, {
                            class: "text-capitalize",
                            color: "primary-darken-3",
                            variant: "tonal"
                          }, {
                            default: _withCtx(() => _cache[3] || (_cache[3] = [
                              _createTextVNode(" Editar ")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 2
                      }, 1032, ["to"]))
                    : _createCommentVNode("", true)
                ])
              ]),
              "no-data": _withCtx(() => _cache[4] || (_cache[4] = [
                _createTextVNode(" Não há nenhum beneficiário ")
              ])),
              loading: _withCtx(() => [
                _createVNode(_component_v_skeleton_loader, { type: "table-row@10" })
              ]),
              _: 2
            }, 1032, ["headers", "items", "loading", "items-per-page"]),
            _createVNode(_component_ItemsPerPage, {
              class: "mt-15",
              total_pages: _ctx.pagination.totalPages,
              total_items: _ctx.pagination.totalElements,
              "onUpdate:pagination": _cache[0] || (_cache[0] = (pagination) => _ctx.handleUpdatePageProps(pagination))
            }, null, 8, ["total_pages", "total_items"])
          ])
        ])
      ]),
      _: 1
    })
  ], 64))
}