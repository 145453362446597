import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_autocomplete = _resolveComponent("v-autocomplete")!

  return (_openBlock(), _createBlock(_component_v_autocomplete, {
    label: "Selecione um Status",
    items: _ctx.statuses,
    "item-value": 'value',
    "item-title": 'name',
    "return-object": false,
    clearable: false,
    "hide-details": !_ctx.required,
    "no-data-text": 'Nenhum status disponível',
    "onUpdate:modelValue": _cache[0] || (_cache[0] = (value) => _ctx.$emit('update:value', value))
  }, null, 8, ["items", "hide-details"]))
}